<template>
  <el-dialog title="类型" class="info-div" style="padding-top: 10px">
  <el-form :model="form" ref="form">
      <el-form-item label="类型名称" :label-width="formLabelWidth">
        <el-input v-model="Info.name" rows="8" maxlength="3000" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDiv">取消</el-button>
        <el-button type="primary" @click="subData">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import {informationTypeEdit} from "@/api/notice.js"
export default {
  name: "index",
  props:['Row'],
  data(){
    return{
      Info:{}
    }
  },
  methods:{
    closeDiv(){
      this.$emit('closeDiv');
    },
    getInfo(row){//清空表单
      if(row) {
        this.Info = row;
      }
    },
    subData(){
      informationTypeEdit(this.Info).then(res=>{
        if(res.success) {
          this.$root.ElMessage({type: 'success', message: '保存成功'});
          this.$emit('search');
        }else{
          this.$root.ElMessage.error(res.message);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>